<template>
  <section class="app-main">
    <el-row>
      <el-col :xs="{ offset: 0, span: 24 }" :md="{ offset: 0, span: 24 }">
        <keep-alive :include="cachedViews">
          <router-view :key="key" />
        </keep-alive>
      </el-col>
    </el-row>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  position: relative;
  font-size: 14px;
  overflow: hidden;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

@media only screen and (max-width: 470px) {
  .app-main {
    padding: 10px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
