<template>
  <!-- <div :class="classObj" class="app-wrapper"> -->
  <div class="app-wrapper">
    <navbar />
    <div class="main-container">
      <app-main />
    </div>
    <!-- <footers /> -->
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import AppMain from './components/AppMains.vue';
// import Footers from'./components/Footers.vue'
import ResizeMixin from './mixin/ResizeHandler.js';
import { mapState } from 'vuex';

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    // Footers
  },

  mixins: [ResizeMixin],

  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      showSettings: (state) => state.settings.showSettings,
      fixedHeader: (state) => state.settings.fixedHeader,
    }),
    classObj() {
      return {
        hideSidebar: false,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
@import '@/styles/variables.scss';

.main-container {
  transition: margin-left 0.28s;
  position: relative;
  flex: 1;
  margin-left: $sideBarWidth;
  padding-top: $topContainer;
  min-height: 100vh;
}

.app-wrapper {
  overflow: auto;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  opacity: 1;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
