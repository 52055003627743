import Vue from 'vue';

const title = '精医链诊所管理平台';

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${Vue.i18n.translate(pageTitle)} - ${title}`;
  }
  return `${title}`;
}
