import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import Layout from "@/layout/index.vue";

export const constantRoutes = [
  {
    path: "/login",
    name: "登录",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/",
    redirect: "/dashboard",
    name: "首页", // 首页
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/dashboard",
        component: () => import("@/views/dashboard/index.vue"),
      },
    ],
  },

  {
    path: "/settings",
    name: "管理", // 平台服务
    component: Layout,
    hidden: true,
    redirect: "/settings/clinic",
    children: [
      {
        path: "/settings/clinic",
        component: () => import("@/views/settings/index.vue"),
        redirect: "/settings/clinic/baseinfo",
        children: [
          {
            path: "/settings/clinic/baseinfo",
            component: () => import("@/views/settings/clinic/index.vue"),
            children: [
              {
                path: "/settings/clinic/baseinfo", //诊所设置
                component: () => import("@/views/settings/clinic/baseinfo.vue"),
              },
              {
                path: "/settings/clinic/departments", //科室设置
                component: () =>
                  import("@/views/settings/clinic/departments.vue"),
              },
              {
                path: "/settings/clinic/employees", //成员管理
                component: () =>
                  import("@/views/settings/clinic/employees.vue"),
              },
              {
                path: "/settings/clinic/equipments", //医疗设备
                component: () =>
                  import("@/views/settings/clinic/equipments.vue"),
              },
              {
                path: "/settings/clinic/license", //证照资质
                component: () => import("@/views/settings/clinic/license.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/settings/diagnosistreatment",
        component: () => import("@/views/settings/index.vue"),
        redirect: "/settings/diagnosistreatment/examinations",
        children: [
          {
            path: "/settings/diagnosistreatment/examinations",
            component: () =>
              import("@/views/settings/diagnosistreatment/index.vue"),
            children: [
              {
                path: "/settings/diagnosistreatment/registrationfee", //挂号费
                component: () =>
                  import(
                    "@/views/settings/diagnosistreatment/registrationfee.vue"
                  ),
              },
              {
                path: "/settings/diagnosistreatment/examinations", //检验
                component: () =>
                  import(
                    "@/views/settings/diagnosistreatment/examinations.vue"
                  ),
              },
              {
                path: "/settings/diagnosistreatment/inspect", //检查
                component: () =>
                  import("@/views/settings/diagnosistreatment/inspect.vue"),
              },
              {
                path: "/settings/diagnosistreatment/treatments", //治疗理疗
                component: () =>
                  import("@/views/settings/diagnosistreatment/treatments.vue"),
              },
              {
                path: "/settings/diagnosistreatment/others", //其他费用
                component: () =>
                  import("@/views/settings/diagnosistreatment/others.vue"),
              },
              {
                path: "/settings/diagnosistreatment/compose", //套餐
                component: () =>
                  import("@/views/settings/diagnosistreatment/compose.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/settings/barCode",
        component: () => import("@/views/settings/index.vue"),
        redirect: "/settings/barCode",
        children: [
          {
            path: "/settings/barCode",
            component: () => import("@/views/settings/barCode/index.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/cashier",
    name: "付费", // 平台服务
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/cashier",
        component: () => import("@/views/cashier"),
      },
    ],
  },
  {
    path: "/OutwardDelivery",
    name: "外送", // 平台服务
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/OutwardDelivery",
        component: () => import("@/views/OutwardDelivery"),
      },
    ],
  },
  {
    path: "/custom",
    name: "客户管理", // 平台服务
    component: Layout,
    hidden: true,

    children: [
      {
        path: "/custom",
        component: () => import("@/views/custom"),
      },
    ],
  },
  {
    path: "/dealer",
    name: "经销商管理", // 平台服务
    component: Layout,
    hidden: true,

    children: [
      {
        path: "/dealer",
        component: () => import("@/views/dealer"),
      },
    ],
  },
  {
    path: "/referral",
    name: "转诊", // 平台服务
    component: Layout,
    hidden: true,

    children: [
      {
        path: "/referral",
        component: () => import("@/views/referral"),
      },
    ],
  },
  {
    path: "/inspect",
    name: "检查", // 平台服务
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/inspect",
        component: () => import("@/views/inspect"),
      },
    ],
  },
  {
    path: "/examination",
    name: "检验", // 平台服务
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/examination",
        component: () => import("@/views/examination"),
      },
    ],
  },
  {
    path: "/order",
    name: "订单", // 平台服务
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/order",
        component: () => import("@/views/order"),
      },
    ],
  },
  {
    path: "/accept",
    name: "检验", // 平台服务
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/accept",
        component: () => import("@/views/accept"),
      },
    ],
  },
  {
    path: "/crm",
    name: "患者", // 平台服务
    component: Layout,
    hidden: true,
    redirect: "/crm",
    children: [
      {
        path: "/crm",
        component: () => import("@/views/crm"),
      },
    ],
  },
  {
    path: "/execute",
    name: "执行", // 平台服务
    component: Layout,
    hidden: true,
    redirect: "/execute",
    children: [
      {
        path: "/execute",
        component: () => import("@/views/execute"),
      },
    ],
  },
  {
    path: "/medicine",
    name: "小药库", // 平台服务
    component: Layout,
    hidden: true,
    redirect: "/medicine",
    children: [
      {
        path: "/medicine",
        component: () => import("@/views/medicine"),
      },
    ],
  },
  {
    path: "/inventory",
    name: "库存", // 平台服务
    component: Layout,
    hidden: true,
    redirect: "/inventory",
    children: [
      {
        path: "/inventory",
        component: () => import("@/views/inventory"),
      },
    ],
  },
  {
    path: "/report",
    name: "检验报告", // 平台服务
    component: Layout,
    hidden: true,
    redirect: "/report",
    children: [
      {
        path: "/report",
        component: () => import("@/views/report"),
      },
    ],
  },
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  // 404 page must be placed at the end !!!
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
];

const createRouter = () =>
  new Router({
    scrollBehavior: () => ({
      y: 0,
    }),
    // mode: "history",
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
