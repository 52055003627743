import router from './router';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { getToken } from '@/utils/auth'; // get token from cookie
import getPageTitle from '@/utils/get-page-title';

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ['/login'];

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();

  // set page title
  document.title = getPageTitle(to.meta.title);

  // 从cookie中获取toekn
  const hasToken = getToken();

  if (hasToken) {
  console.log(11111111);

    // 如果token存在，并且访问路径是/login，则直接跳转到主页
    if (to.path === '/login') {
      next({ path: '/' });
      NProgress.done();
    } else {
      next();
      // determine whether the user has obtained his permission roles through getInfo
    }
  } else {
    // 处理token不存在的情况

    if (whiteList.indexOf(to.path) !== -1) {

      // whiteList.indexOf(to.path) !== -1
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
