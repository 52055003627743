import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function dynamicFields(fields,form) {  
  return fields.map(field => {  
    console.log(field);
    let tag = field.type;  
    if (tag === 'input') {  
      return (  
        <el-form-item label={field.label} prop={field.prop}>  
          <el-input v-model={form[field.prop]}></el-input>  
        </el-form-item>  
      );  
    } else if (tag === 'select') {  
      // 添加下拉列表或其他类型表单项的逻辑  
    } else if (tag === 'radio') {  
      // 添加单选框或其他类型表单项的逻辑  
    } else if (tag === 'checkbox') {  
      // 添加复选框或其他类型表单项的逻辑  
    } else {  
      // 其他类型的表单项逻辑  
    }  
  });  
}

export function formatDate(value) { // 时间戳转换日期格式方法
  if (value == null) {
    return ''
  } else {
    const date = new Date(value)
    const y = date.getFullYear()// 年
    let MM = date.getMonth() + 1 // 月
    MM = MM < 10 ? ('0' + MM) : MM
    let d = date.getDate() // 日
    d = d < 10 ? ('0' + d) : d
    return y + '-' + MM + '-' + d
  }
}

export function formatDateTime(value) { // 时间戳转换日期格式方法
  if (value == null) {
    return ''
  } else {
    const date = new Date(value)
    const y = date.getFullYear() // 年
    let MM = date.getMonth() + 1 // 月
    MM = MM < 10 ? ('0' + MM) : MM
    let d = date.getDate() // 日
    d = d < 10 ? ('0' + d) : d
    let h = date.getHours() // 时
    h = h < 10 ? ('0' + h) : h
    let m = date.getMinutes()// 分
    m = m < 10 ? ('0' + m) : m
    let s = date.getSeconds()// 秒
    s = s < 10 ? ('0' + s) : s
    return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
    }
  }