import request from '@/utils/request';
import { api, objFormData } from './index.js';
import { getToken } from '@/utils/auth'; // get token from cookie
var token = getToken()
export function upload(data) {
  return request({
    url: api.upload, // 文件传输
    method: 'post',
    data: objFormData(data),
  });
}
export function resetpwd(data) {
  data.token = token
  return request({
    url: api.resetpwd, // 文件传输
    method: 'post',
    data: objFormData(data),
  });
}
export function sendApply(data) {
  data.token = token
  return request({
    url: api.sendApply, // 文件传输
    method: 'post',
    data: objFormData(data),
  });
}
export function getRegister(data) {
  return request({
    url: api.register, // 注册
    method: 'post',
    data: objFormData(data),
  });
}
export function getLogin(data) {
  return request({
    url: api.login,
    method: 'post',
    data: objFormData(data),
  });
}
export function send(data) {
  return request({
    url: api.send,
    method: 'post',
    data: objFormData(data),
  });
}
export function addUser(data) {
  data.token = token
  return request({
    url: api.addUser,
    method: 'post',
    data: objFormData(data),
  });
}
export function UserGetList(data) {
  data.token = token
  return request({
    url: api.UserGetList,
    method: 'post',
    data:  objFormData(data),
  });
}
export function getGroup() {
  return request({
    url: api.getGroup,
    method: 'post',
    data: objFormData({token}),
  });
}
export function billOrderByPayMonthly(data) {
  data.token = token

  return request({
    url: api.billOrderByPayMonthly,
    method: 'post',
    data: objFormData(data),
  });
}
export function createBill(data) {
  data.token = token
  return request({
    url: api.createBill,
    method: 'post',
    data: objFormData(data),
  });
}

export function getBillList(data) {
  data.token = token
  return request({
    url: api.getBillList,
    method: 'post',
    data: objFormData(data),
  });
}
export function setReceivable(data) {
  data.token = token
  return request({
    url: api.setReceivable,
    method: 'post',
    data: objFormData(data),
  });
}
export function voidBill(data) {
  data.token = token
  return request({
    url: api.voidBill,
    method: 'post',
    data: objFormData(data),
  });
}
export function payment(data) {
  data.token = token
  return request({
    url: api.payment,
    method: 'post',
    data: objFormData(data),
  });
}
export function paymentLog(data) {
  data.token = token
  return request({
    url: api.paymentLog,
    method: 'post',
    data: objFormData(data),
  });
}
export function BillGetList(data) {
  data.token = token
  return request({
    url: api.BillGetList,
    method: 'post',
    data: objFormData(data),
  });
}

// export function login() {
//   return request({
//     url: '/user/login', //登录
//     method: 'post'
//   })
// }

