<template>
  <div class="saas-navbar">
    <div class="userInfo">
      <em>精医检</em>
      <el-dropdown
        trigger="click"
        style="margin-right: 20px"
        placement="bottom"
        @command="onloginOut"
      >
        <span class="el-dropdown-link">
          {{ nickname }}（{{ username }}）<i
            class="el-icon-caret-bottom el-icon--right"
          ></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item icon="el-icon-s-promotion" style="color: #000">
            王某（诊所名字待定）</el-dropdown-item
          > -->
          <el-dropdown-item command="2" icon="el-icon-add" style="color: #000">
            重置密码</el-dropdown-item
          >
          <el-dropdown-item command="1" icon="el-icon-add" style="color: #000">
            切换诊所</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="box">
      <router-link
        v-for="(item, index) in menu"
        :key="index"
        :index="index.toString()"
        tag="div"
        :to="item.key"
        replace
      >
        {{ item.title }}
      </router-link>
    </div>
    <div class="menu"></div>
    <el-dialog
      title="重置密码"
      :visible.sync="dialogFormVisible"
      v-if="dialogFormVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="400px"
    >
      <el-form
        :model="form"
        style="margin-top: 20px"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="新密码" label-width="120px" prop="newpassword">
          <el-input v-model="form.newpassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号" label-width="120px">
          <el-input v-model="form.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="验证码"
          class="yzm"
          label-width="120px"
          prop="captcha"
        >
          <el-input v-model="form.captcha" style="width: 110px"></el-input>
          <el-button @click="mobile" v-if="showTime">获取验证码</el-button>
          <el-button v-else>{{ dateItem }}秒后重试</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { removeToken } from "@/utils/auth";
import { resetpwd, send } from "@/api/user";
export default {
  components: {},
  data() {
    return {
      form: { newpassword: "", captcha: "", mobile: "" },
      nickname: "",
      username: "",
      activeIndex: 13,
      dialogFormVisible: false,
      dateItem: 60,
      showTime: true,
      menu: [
        { title: "门诊", key: "/dashboard" },
        { title: "执行", key: "/execute" },
        // { title: "收费", key: "/cashier" },
        { title: "接收", key: "/OutwardDelivery" },
        { title: "订单", key: "/order" },
        // { title: "录入检验结果", key: "/examination" },
        // { title: "检验报告", key: "/report" },
        // { title: "经销商检验", key: "/accept" },
        // { title: '外送检验', key: '/safety' },
        { title: "患者", key: "/crm" },
        { title: "经销商管理", key: "/dealer" },
        { title: "供应商管理", key: "/custom" },
        { title: "管理", key: "/settings" },
        { title: "小药库", key: "/medicine" },
        { title: "库存", key: "/inventory" },
      ],
      rules: {
        newpassword: [
          { required: true, message: "请输入新填写的密码", trigger: "change" },
        ],
        captcha: [
          { required: true, message: "请输入验证码", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    const userInfo = localStorage.getItem("userInfo");
    this.username = JSON.parse(userInfo).username;
    this.nickname = JSON.parse(userInfo).nickname;
  },
  methods: {
    mobile() {
      var Reg = /^[1][34578][0-9]{9}$/;

      if (this.form.mobile == "") {
        return this.$message.error("请输入手机号");
      } else {
        if (Reg.test(this.form.mobile)) {
          send({ mobile: this.form.mobile, event: "register" }).then((res) => {
            if (res.code == 0) {
              this.$message.error(res.msg);
            } else {
              let timer = setInterval(() => {
                this.dateItem -= 1;
                if (this.dateItem <= 0) {
                  clearInterval(timer);
                  this.showTime = true;
                  this.dateItem = 60;
                }
              }, 1000);
              this.showTime = false;
            }
          });
        } else {
          return this.$message.error("请输入正确手机号");
        }
      }
    },
    onConfirm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          var data = {
            captcha: this.form.captcha,
            newpassword: this.form.newpassword,
          };
          resetpwd(data).then((res) => {
            if (res.code == 1) {
              this.$message({ type: "success", message: res.msg });
              removeToken();
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleRoute(path) {
      this.$router.push(path);
    },
    onloginOut(e) {
      if (e == 1) {
        removeToken();
        localStorage.clear();
        location.href = '/login'
        // this.$router.push({
        //   path: "/login",
        // });
      } else {
        this.dialogFormVisible = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.saas-navbar {
  height: 48px;
  left: 0;
  margin: 0 auto;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: top 0.28s ease-out;
  -o-transition: top 0.28s ease-out;
  transition: top 0.28s ease-out;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1d8ca2;
  .box {
    display: flex;
    div {
      cursor: pointer;
      padding: 0px 8px;
      color: #fff;
      margin-left: 10px;
      border-radius: 4px;
      text-align: center;
      height: 25px;
      line-height: 25px;
      font-size: 14px;
      position: relative;
      &:hover {
        background: #d7d4d44e;
      }
      &.router-link-active {
        font-weight: bold;
        &::after {
          content: "";
          position: absolute;
          background: #fff;
          width: 100%;
          height: 2px;
          bottom: -5px;
          left: 0;
        }
      }
    }
  }
  .userInfo {
    // margin-right: auto;
    position: absolute;
    left: 120px;
    em {
      color: #fff;
      font-weight: bold;
      font-size: 18px;
    }
    .el-dropdown-link {
      color: #fff;
      font-weight: bold;
      margin-left: 20px;
    }
  }
}
.el-menu-demo {
  display: flex;
  justify-content: center;
}
.nav {
  span {
  }
}
</style>
