import axios from "axios";
// import {Loading} from "element-ui";
import store from "@/store";
// import { getToken } from "@/utils/auth";
const service = axios.create({
  baseURL: "http://47.93.222.12/api",
  withCredentials: false,
  headers: { "Content-Type": "multipart/form-data" },
});
// let loading;
service.interceptors.request.use(
  (config) => {
    // loading = Loading.service({
    //   lock: true,
    //   text: "处理中，请稍等...",
    //   spinner: "el-icon-loading",
    //   background: "rgba(0, 0, 0, 0.5)",
    //   customClass: "loadingstyle",
    // });
  
    return config;
  },
  (error) => {
    console.log(error); // for debug
    // loading.close();
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    // loading.close();
    const res = response.data;
    if (res.code !== 1 && res.code !== 0) {
      // Message({
      //   message: res.msg || "Error",
      //   type: "error",
      //   duration: 3 * 1000,
      //   dangerouslyUseHTMLString: true,
      // });
      // token失效
      return Promise.reject(new Error(res.msg || "Error"));
    } else {
      // if (res.code == 0) {
      //   Message({
      //     message: res.msg,
      //     type: "error",
      //   });
      // } else {
      // }

      return res;

    }
  },
  (error) => {
    if (error) {
      // loading.close();
      const status = error.response.status;
      if (status == 401) {
        store.dispatch("user/resetToken").then(() => {
          // location.reload();
        });
      }
    }

    // if (res.code === 401 || res.code === 10032) {
    //   // 清空token，页面reload，跳转到登录页
    // }
    console.log("err" + error); // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 2 * 1000
    // })
    return Promise.reject(error);
  }
);

export default service;
