var URL = "https://xyk.jingyilian.cn/api";
var baseURL = "https://mzapi.jingyilian.cn/api";

var api = {
  upload: baseURL + "/common/upload", // 账号密码登录
  login: baseURL + "/user/login", // 账号密码登录
  register: baseURL + "/user/register", //注册
  resetpwd: baseURL + "/user/resetpwd", //修改密码
  UserGetList: baseURL + "/User/getList", //注册User/getList
  addUser: baseURL + "/User/addUser", //注册User/getList
  send: baseURL + "/sms/send", //注册
  getGroup: baseURL + "/Clinic/getGroup", //角色列表

  getInfo: baseURL + "/clinic/getInfo", //诊所信息查询
  getClientClinic: baseURL + "/Clinic/getClientClinic", //获取关联诊所列表
  setInfo: baseURL + "/clinic/setInfo", //诊所信息查询
  getdevicelist: baseURL + "/clinic/getdevicelist", //设备查询
  setdevice: baseURL + "/clinic/setdevice", //设备查询
  DerpartList: baseURL + "/clinic/getDerpartList", //设备科室
  addDepart: baseURL + "/clinic/addDepart", //设备科室
  setCode: baseURL + "/Clinic/setCode", //查看邀请码
  setSpecial: baseURL + "/Clinic/setSpecial", //设置管理客户信息
  clientInspect: baseURL + "/Clinic/clientInspect", //查询客户检验项目列表

  getProject: baseURL + "/Project/getProject", //获取单项检验项目列表
  getInspectList: baseURL + "/Project/getInspectList", //获取检验项目列表（管理-检验项目中用）
  addProject: baseURL + "/Project/addProject", //添加单项检验项目
  addProjects: baseURL + "/Project/addProjects", //添加组合检验项目
  inspectInfo: baseURL + "/Project/inspectInfo", //添加获取检验列表详情

  getSampleType: baseURL + "/Sample/getSampleType", //样本容器列表
  getContainer: baseURL + "/Sample/getContainer", //获取单项检验项目列表
  addContainer: baseURL + "/Sample/addContainer", //添加样本容器
  delContainer: baseURL + "/Sample/delContainer", //删除样本容器

  
  addInspect: baseURL + "/Inspect/addInspect", //查询客户检验项目列表
  setPrintStatus: baseURL + "/Inspect/setPrintStatus", //修改打印状态
  getReoprtList: baseURL + "/Inspect/getReoprtList", //查询客户检验项目列表

  getReceiveList: baseURL + "/Inspect/getReceiveList", //获取接收的检验订单
  getDetail: baseURL + "/Inspect/getDetail", //获取接收的检验订单
  getList: baseURL + "/Inspect/getList", //获取接收的检验订单
  setCheck: baseURL + "/Inspect/check", //审核人
  setInspector: baseURL + "/Inspect/inspector", //检验人
  reportJson: baseURL + "/Inspect/reportJson", //检验结果
  report: baseURL + "/Inspect/report", //检验结果
  getListByPaid: baseURL + "/Inspect/getListByPaid", //检验结果
  // 小药库

  //供应商管理 /api/Association/getSupplier
  getSupplier: baseURL + "/Association/getSupplier", // 供应商
  AsssetSpecial: baseURL + "/Association/setSpecial", // 设置供应商销售价格api/Association/getSupplier
  getSupplierInspect: baseURL + "/Association/getSupplierInspect", // 设置供应商销售价格api/Association/getSupplier
  getClinicInfo: baseURL + "/Association/getClinicInfo", // 设置供应商销售价格api/Association/getSupplier
  inviteRecord: baseURL + "/Association/inviteRecord", // 设置供应商销售价格api/Association/getSupplier
  setStatus: baseURL + "/Association/setStatus", // 设置供应商销售价格api/Association/getSupplier
  getClientDetail: baseURL + "/Association/getClientDetail", // 获取经销商供应商详情/Association/getSupplier
  sendApply: baseURL + "/Association/sendApply", //添加好友
  getClientList: baseURL + "/Association/getClientList", //获取接收的检验订单
  setClientInfo: baseURL + "/Association/setClientInfo", //设置管理客户信息


  setaddCase: baseURL + "/patient/addCase", // 添加病例
  getPatientList: baseURL + "/Execute/getPatientList", // 患者列表
  getSample: baseURL + "/Execute/getSample", // 患者列表
  createCode: baseURL + "/BarCode/createCode", // 患者列表
  getBatch: baseURL + "/BarCode/getBatch", // 患者列表
  getCode: baseURL + "/BarCode/getCode", // 患者列表
  ExecuteSetCode: baseURL + "/Execute/setCode", // 患者列表


  addPatient: baseURL + "/patient/addPatient", //添加患者
  getCrmList: baseURL + "/patient/getList", //添加患者


  logGetReceiveList: baseURL + "/Logistics/getReceiveList", //样本签收列表
  setReceive: baseURL + "/Logistics/receive", //实验室签收
  logGetList: baseURL + "/Logistics/getList", //获取客户待取的样本信息


  billOrderByPayMonthly: baseURL + "/Bill/billOrderByPayMonthly", //获取客户订单列表
  createBill: baseURL + "/Bill/createBill", //订单列表结算
  getBillList: baseURL + "/Bill/getBillList", //订单列表结算
  setReceivable: baseURL + "/Bill/setReceivable", //确定应收款
  voidBill: baseURL + "/Bill/voidBill", //确定应收款
  payment: baseURL + "/Bill/payment", //确定应收款
  paymentLog: baseURL + "/Bill/paymentLog", //确定应收款
  BillGetList: baseURL + "/Bill/getList", //确定应收款



  // 小药库
  rukulist: URL + "/ruku/rukulist", // 入库
  addruku: URL + "/Ruku/addruku", // 添加入库
  searchmyselfmedic: URL + "/Medicine/searchmyselfmedic", // 添加入库
  getmediclist: URL + "/medicine/getmediclist", // 入库列表
  gyslist: URL + "/Gongyingshang/list", // 供应商列表 /pandian/pandianlist?token=zcx&addutype=1
  setSave: URL + "/Gongyingshang/save", // 供应商列表 /pandian/pandianlist?token=zcx&addutype=1
  pandianlist: URL + "/pandian/pandianlist", // 盘点列表 /pandian/pandianlist?token=zcx&addutype=1
  searchbuymedic: URL + "/Order/searchbuymedic", // 盘点列表 /pandian/pandianlist?token=zcx&addutype=1
  chukulist: URL + "/chuku/chukulist", // 盘点列表 /pandian/pandianlist?token=zcx&addutype=1
  orderlistbypc: URL + "/order/orderlistbypc", // 盘点列表 /pandian/pandianlist?token=zcx&addutype=1
  addorder: URL + "/Order/addorder", // 盘点列表 /pandian/pandianlist?token=zcx&addutype=1
  orderinfo: URL + "/Order/orderinfo", // 盘点列表 /pandian/pandianlist?token=zcx&addutype=1
  addmedicine: URL + "/medicine/addmedicine", // 新增药品/pandian/pandianlist?token=zcx&addutype=1
  findmedicineclass: URL + "/medicine/findmedicineclass", // 获取分类/pandian/pandianlist?token=zcx&addutype=1
  searchmedicpici: URL + "/Medicine/searchmedicpici", // 新增药品/pandian/pandianlist?token=zcx&addutype=1
  addchuku: URL + "/chuku/addchuku", // 新增出库单 /pandian/pandianlist?token=zcx&addutype=1
  clconngys: URL + "/gongyingshang/clconngys", // 新增出库单 /pandian/pandianlist?token=zcx&addutype=1
  addpdsingle: URL + "/pandian/addpdsingle", // 新增出库单 /pandian/pandianlist?token=zcx&addutype=1
}
function objFormData(obj) {
  const formData = new FormData();
  Object.keys(obj).map((key) => {
    formData.append(key, obj[key]);
  });
  return formData;
}

export { api, objFormData };
